import { GetByIdFn, GetByNullableIdFn, GetFn, SendByIdFn } from 'api';
import {
	GrauDeParentescoEnum,
	PerfilInvestimentoEnum,
	Range,
	RegimeTributacaoEnum,
	SituacaoParticipacaoEnum,
	TipoBeneficioEnum,
	TipoContribuicaoEnum,
	TipoEmailEnum,
	TipoEnderecoEnum,
	TipoParticipanteEnum,
	TipoTelefoneEnum,
} from 'lib';

import { BaseApi } from './BaseApi';

// Contribuições
export type ContribuicaoType = { nome: string; valor: number; percentual: number };

export type GetContribuicoesReturnType = {
	totalParticipante: number;
	contribuicoesParticipante: ContribuicaoType[];

	totalPatrocinadora: number;
	contribuicoesPatrocinadora: ContribuicaoType[];
};

export type GetTotaisContribuicoesReturnType = {
	totalContribuicaoParticipanteUltimoMes: number;
	totalContribuicaoPatrocinadoraUltimoMes: number;
	totalContribuicaoUltimoMes: number;
	totalContribuicoesOutrosPlanos: number;
};

/**
 * Meses vão de 1 a 12.
 *
 * https://learn.microsoft.com/pt-br/dotnet/api/system.datetime.month?view=netcore-3.1
 */
type HistoricoContribuicaoType = { mes: Range<1, 13>; valor: number };
export type GetHistoricoMensalContribuicoesReturnType = {
	contribuicoesParticipante: HistoricoContribuicaoType[];
	contribuicoesPatrocinadora: HistoricoContribuicaoType[];
};

export type AlteracaoCusteioConfigContribuicao = {
	percentualMinimo: number;
	percentualMaximo: number;
	multiploPercentual: number;
};
export type AlteracaoCusteioConfigConta = {
	contribuicaoPlanoId: number;
	contribuicaoPlano: string;
	contribuicaoPlanoCodigo: TipoContribuicaoEnum;
	contribuicaoTotalNoMes: number;
	percentualAtual: number;
	valorAtual: number;
	contrapartidaAtual: number;
	configsContribucoes: AlteracaoCusteioConfigContribuicao[];

	percentualContrapartida: number;
};
export type GetContribuicoesAlteracaoCusteioContasReturnType = {
	configsContas: AlteracaoCusteioConfigConta[];
	salarioParticipacao: number;
};

export type PostContribuicoesAlteracaoCusteioContasPayloadType = {
	simulacaoPlano: { contribuicaoId: number; novoPercentual: number }[];
};

export type GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType = {
	parametrosCalculo: {
		saldoInicial: number;
		idadeAtual: number;
		retornoInvestimentoAnual: number;
		crescimentoRealContribuicoes: number;
		idadeProjetada: number;
	};
	estimativaAcumuloAtual: number;
};

export type GetContribuicoesSimulacoesEstimativaAcumuloReturnType = {
	cenarioFavoravel: number;
	melhorEstimativa: number;
	cenarioDesfavoravel: number;
};

export type GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType = {
	parametrosCalculo: {
		salarioParticipacao: number;
		mesAtual: number;
		fatorBeneficio: number;
	};

	beneficioFiscalAtual: number;
};

// Benefícios
export type SaldoInfo = { valorTotal: number; valorResgate: number; percentualResgate: number };
export type GetBeneficiosSaldosReturnType = {
	saldoTotal: SaldoInfo;
	saldoParticipante: SaldoInfo;
	saldoPortabilidade: SaldoInfo;
	saldoPatrocinadora: SaldoInfo;

	// TODO - Provisório, adequar isso aqui
	emprestimo?: {
		saldoIsento: number;

		saque: { valor: number; percentual: number };
		saldos?: {
			saldo10: number;
			saldo15: number;
			saldo20: number;
			saldo25: number;
			saldo30: number;
			saldo35: number;
		};
	};
};

type ItemFolhaBeneficioFichaAssistida = { nome: string; valor: number };
type ItemMensalFichaAssistida = {
	mes: Range<1, 13>;
	itensFolhaBeneficio: ItemFolhaBeneficioFichaAssistida[];
};

export type GetFichaFinanceiraAssistidoReturnType = { ano: number; itensMensais: ItemMensalFichaAssistida[] };
export type GetFichaFinanceiraAssistidoArgs =
	| { filtro: 'UmAno' | 'DoisAnos' }
	| { filtro: 'Custom'; anoInicio: number; anoFim: number };

export type GetBeneficiosContrachequeReturnType = {
	beneficioId: number;
	pagamentoId: number;
	descricaoPagamento: string;
	tipoBeneficio: TipoBeneficioEnum;
	dataCompetencia: string; // Date
	dataPagamento: string; // Date
	totalProventos: number;
	totalDescontos: number;
	liquido: number;

	itemFolha: { descricao: string; valor: number }[];
};

export type GetBeneFiciosRendasReturnType = {
	tipoBeneficio: string;
	tipoRenda: string;
	dataInicio: string; // Date
	dataInicioPagamento: string; // Date
	dataFimPrevista: string | null; // Date
};

type SaldoDetalhadoContaDetalhes = {
	contaPlano: string;
	percentualResgateContaPlano: number;
	valorSaldo: number;
	valorSaldoCotas: number;
};

type SaldoDetalhadoSaldo = {
	contasPlano: SaldoDetalhadoContaDetalhes[];
	percentualResgate: number;
	valorTotal: number;
};

export type GetBeneficiosSaldoDetalhadoReturnType = {
	saldoParticipante: SaldoDetalhadoSaldo;
	saldoPatrocinadora: SaldoDetalhadoSaldo;
	valorTotal: number;
};

export type GetBeneficiariosReturnType = {
	key: number;
	cpf: string;
	dataNascimento: string;
	nome: string;
	parentesco: GrauDeParentescoEnum;
	/**
	 * Multiplicar por 100!
	 *
	 * 1 = 100%, 0.5 = 50%, etc.
	 */
	percentualRecebimento: number;
	total: string;
	/** TODO - alterar API para retornar os dois valores abaixo */
	dependenteLegalSn: boolean;
	dependenteImpRendaSn: boolean;
	email: string;
	telefone: string;
};

// Participações
export type GetParticipacoesReturnType = {
	salario: number;
	perfisAtivos: string[];
};

export type GetParticipacoesBeneficiosReturnType = {
	idBeneficio: number;
	nome: string;
	dataInicio: string;
	dataInicioPagamento: string;
};

export type SaldoHistoricoMensal = {
	mes: Range<1, 13>;
	totalParticipante: number;
	totalPatrocinadora: number;
};

export type PartipacoesSaldos = {
	tipo: string;
	valor: number;
	percentual: number;
};

export type GetParticipacoesSaldoReturnType = {
	saldoTotal: number;
	saldosParticipante: PartipacoesSaldos[];
	saldosPatrocinadora: PartipacoesSaldos[];
	historicoMensal: SaldoHistoricoMensal[];
};

export type GetParticipacoesComposicaoSaldoReturnType = {
	outros: number;
	saldoParticipante: number;
	saldoPatrocinadora: number;
	saldoPortabilidade: number;
	rentabilidade: number;
	valorSaida: number;
	saldoTotal: number;
};

export type SimulacaoSaldoInfo = { valorTotal: number; valorMes: number };

export type MinhaRentabilidadeInfo = {
	key: number;
	periodo: string;
	porcentagem: string;
	valorizacao: number;
};

export type GetMinhaRentabilidadeReturnType = {
	perfil: string;
	minhaRentabilidadeInfo: MinhaRentabilidadeInfo[];
};

export type Emprestimo = {
	valorSolicitado: number;
	saldoDevedor: number;
	qtdParcelas: number;
};

export type GetEmprestimoReturnType = {
	meusEmprestimos: Emprestimo[];
};

export type GetComposicaoSaldoReturnType = {
	valorParticipante: number;
	valorPatrocinadora: number;
	rendimento: number;
	valorSaida: number;
	totalAcumulado: number;
};

export type GetParticipacoesDadosPessoaisReturnType = {
	podeEditar: boolean;

	nome: string;
	// CPF PRECISA SER STRING, do contrário teremos bugs quando começar com 0!
	// TODO - Isso precisa começar a vir como string da API!
	cpf: string;
	nascimento: string;
	tipoSexo: string;
	estadoCivil: string;
	nomeMae: string;
	nomePai: string;
	isResidenteExterior: boolean;
	isUsPerson: boolean;
	nacionalidade: string;
	naturalidade: string;
};

export type PostParticipacoesDadosPessoaisPayloadType = Omit<
	GetParticipacoesDadosPessoaisReturnType,
	'podeEditar' | 'tipoSexo' | 'estadoCivil'
> & {
	tipoSexoId: number;
	estadoCivilId: number;
};

export type GetParticipacoesDadosContaBancariaReturnType = {
	podeEditar?: boolean;

	// TODO - Falta ID
	// TODO - Falta nome da conta
	banco: string;
	// TODO - Agencia e CC devem ser string
	agencia: string;
	contaCorrente: string;
	digitoVerificadorContaCorrente: string;
};

export type PostParticipacoesDadosContaBancariaPayloadType = Omit<
	GetParticipacoesDadosContaBancariaReturnType,
	'podeEditar' | 'banco'
> & { tipoDadoBancarioId: number };

export type ParticipacoesDadosContatoTelefone = {
	id?: number;
	dataAtivacao?: string; // Date

	numero: number;
	ddd: number;
	ddi: number;
	tipo: TipoTelefoneEnum;

	telefonePreferencialSn: boolean;
	whatsappSn: boolean;
	smsSn: boolean;
};
export type PostParticipacoesDadosContatoTelefonePayloadType = Omit<
	ParticipacoesDadosContatoTelefone,
	'id' | 'dataAtivacao'
>;

export type ParticipacoesDadosContatoEndereco = {
	id?: number;
	dataAtivacao?: string; // Date

	cep: string;
	numero: string;
	bairro: string;
	uf: string;
	pais: string;
	endereco: string;
	complemento: string;
	municipio: string;
	tipo: TipoEnderecoEnum;

	enderecoPreferencialSn: boolean;
};
export type PostParticipacoesDadosContatoEnderecoPayloadType = Omit<
	ParticipacoesDadosContatoEndereco,
	'id' | 'dataAtivacao'
>;

export type ParticipacoesDadosContatoEmail = {
	id?: number;
	dataAtivacao?: string; // Date

	email: string;
	tipo: TipoEmailEnum;
	emailPreferencialSn: boolean;
};
export type PostParticipacoesDadosContatoEmailPayloadType = Omit<ParticipacoesDadosContatoEmail, 'id' | 'dataAtivacao'>;

export type GetParticipacoesDadosContatoReturnType = {
	podeEditar: boolean;

	enderecos: ParticipacoesDadosContatoEndereco[];
	telefones: ParticipacoesDadosContatoTelefone[];
	emails: ParticipacoesDadosContatoEmail[];
};

export type GetParticipacoesDadosPlanosReturnType = {
	plano: string;
	regimeTributacao: RegimeTributacaoEnum;
	dataAdesaoPlano: string;
	tipoVinculoParticipacao?: string;
	perfilInvestimento?: PerfilInvestimentoEnum;
	opcaoPerfilInvestimento?: PerfilInvestimentoEnum;
	situacaoParticipacao?: SituacaoParticipacaoEnum;
	empregador?: string;
	salario?: number;
	dataAdmissao?: string;
	matricula?: number;
	lotacao?: string;
	municipio?: string;
	// TODO: Solicitar ao responsavel a alteração das informações retornadas && verificar valor no mock
	situacaoEmprego?: string; //////
};

export type GetParticipacoesPpeReturnType = {
	ppe: boolean;
	descricao?: string;
	titulo?: string;
};

export type GetParticipacoesUsPersonReturnType = {
	// As opções vêm do backend nesse formato
	// 'texto da opcao': true
	[key: string]: boolean;
};

// Header
export type GetParticipacoesPlanosReturnType = {
	/** Nome completo do participante */
	nome: string;
	participacaoId: number;
	matricula: number;

	dataAdesao: string; // Date
	dataReferencia: string; // Date

	status: string;
	plano?: string;
	perfil?: string;
	tipoParticipante: TipoParticipanteEnum;
};

// Extrato Contribuicao
export type ExtratoContribuicao = {
	data: Date;
	indice: string;
	conta: string;
	tipo: string;
	evento: string;
	valor: number;
	valorQuota: number;
	quotas: number;
};

export type GetExtratoContribuicaoReturnType = {
	ano: number;
	mes: Range<1, 13>;
	movimentos: ExtratoContribuicao[];
};

export type GetExtratoContribuicaoArgs = {
	filtro: 'UltimosTresMeses' | 'Anual' | 'Custom' | null;
	dataInicio: Date | null;
	dataFim: Date | null;
};

export type GetNotificacoesReturnType = {
	title: string;
	icon?: string;
	new?: boolean;
};

type SaldoExtratoDetalhes = {
	anterior: number;
	movimento: number;
	atual: number;
	rentabilidade: number;
};

type SaldoExtratoContaType = {
	contaPlano: string;
	valores: SaldoExtratoDetalhes;
	cotas: SaldoExtratoDetalhes;
};

type SaldoExtratoIndiceType = {
	indice: string;
	extratoPorContaPlano: SaldoExtratoContaType[];
};

type SaldoExtratoSaldoType = {
	indices: SaldoExtratoIndiceType[];
	valorTotal: SaldoExtratoDetalhes;
};

export type GetParticipacoesSaldoExtratoReturnType = {
	dataSaldoAnterior: string; // Date
	dataSaldoAtual: string; // Date
	saldoParticipante: SaldoExtratoSaldoType;
	saldoPatrocinadora: SaldoExtratoSaldoType;
	valorTotal: SaldoExtratoDetalhes;
};

export type GetParticipacoesSaldoExtratoArgs =
	| { filtro: 'NoMes' | 'NoAno' | 'UltimosDozeMeses' }
	| { filtro: 'Custom'; dataInicio: string; dataFim: string };

// Informações
export type GetInformacoesTipoContaBancariaReturnType = {
	bancoId: number;
	banco: string;
};

export type GetInformacoesTipoSexoReturnType = {
	tipoSexoId: number;
	tipoSexo: string;
};

export type GetInformacoesTipoEstadoCivilReturnType = {
	tipoEstadoCivilId: number;
	tipoEstadoCivil: string;
};

export type PostCalculoInstitutosLegaisPayloadType = {
	recebePortabilidadeSn: boolean;
};

export type PostCalculoInstitutosLegaisReturnType = {
	tipoBeneficio: string;
	tipoBeneficioId: number;
	valorSaldo: number | null;
};

export type GetDadosSimulacaoBeneficioArgs = {
	tipoBeneficioId: number;
};

export type GetDadosSimulacaoBeneficioReturnType = {
	tipoBeneficioId: number;
	valorBeneficioMinimo: number | null;
	dadosParticipante: DadosParticipanteType;
	contribuicoes: PercentuaisContribuicaoType[];
	tipoRendas: TipoBeneficioRendaType[];
};

export type PostRequerimentoBeneficioPayloadType = {
	tipoBeneficioId: number;
	dataRequerimento: Date;
	rendas: RequerimentoBeneficioRenda[];
};

export type RequerimentoBeneficioRenda = {
	tipoRendaId: number;
	numeroMesesParcelas: number;
	percentualSaque: number;
	percentualRenda: number;
	valorSaque: number;
	valorRenda: number;
	opcaoRendaMensalSn: boolean;
	receberAbonoAnualSn: boolean;
};

export type DadosParticipanteType = {
	planoId: number;
	plano: string;
	valorSalario: number | null;
	percentualResgate: number | null;
	saldos: SaldosBeneficioType;
};

export type SaldosBeneficioType = {
	saldoTotalParticipante: number | null;
	saldoTotalPatrocinadora: number | null;
	saldoTotal: number | null;
	saldoResgatavelParticipante: number | null;
	saldoResgatavelPatrocinadora: number | null;
	saldoResgatavelTotal: number | null;
};

export type PercentuaisContribuicaoType = {
	contribuicaoPlano: string;
	valorContribuicao: number;
};

export type TipoBeneficioRendaType = {
	tipoRenda: string;
	tipoRendaId: number;
	mesesParcelasMinimo: number;
	mesesParcelasMaximo: number;
	mesesParcelaVariacao: number;
	percentualRendaSaldoMinimo: number;
	percentualRendaSaldoMaximo: number;
	percentualRendaSaldoVariacao: number;
	percentualRendaReaisMinimo: number;
	percentualRendaReaisMaximo: number;
	percentualSaqueMinimo: number;
	percentualSaqueMaximo: number;
	percentualSaqueVariacao: number;
	multiplicadorBeneficioMinimo: number;
	possuiCalculoSn: boolean;
};

export interface IParticipanteApi {
	getContribuicoesById: GetByIdFn<GetContribuicoesReturnType>;
	getTotaisContribuicoesById: GetByIdFn<GetTotaisContribuicoesReturnType>;
	getHistoricoMensalContribuicoesById: GetByIdFn<GetHistoricoMensalContribuicoesReturnType>;

	getContribuicoesAlteracaoCusteioContasById: GetByIdFn<GetContribuicoesAlteracaoCusteioContasReturnType>;
	postContribuicoesAlteracaoCusteioContasById: SendByIdFn<PostContribuicoesAlteracaoCusteioContasPayloadType>;

	getContribuicoesAlteracaoCusteioEstimativaAcumuloById: GetByIdFn<GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType>;
	getContribuicoesSimulacoesEstimativaAcumuloById: GetByIdFn<GetContribuicoesSimulacoesEstimativaAcumuloReturnType>;
	getContribuicoesAlteracaoCusteioBeneficioFiscalById: GetByIdFn<GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType>;

	getBeneficiosSaldosById: GetByIdFn<GetBeneficiosSaldosReturnType>;
	getBeneficiosContrachequeById: GetByIdFn<readonly GetBeneficiosContrachequeReturnType[]>;
	getBeneficiosRendasById: GetByIdFn<readonly GetBeneFiciosRendasReturnType[]>;
	getBeneficiosSaldoDetalhadoById: GetByIdFn<GetBeneficiosSaldoDetalhadoReturnType>;
	getFichaFinanceiraAssistidoById: GetByIdFn<
		readonly GetFichaFinanceiraAssistidoReturnType[],
		GetFichaFinanceiraAssistidoArgs
	>;

	getParticipacoesById: GetByIdFn<GetParticipacoesReturnType>;
	getParticipacoesPlanosById: GetByNullableIdFn<readonly GetParticipacoesPlanosReturnType[]>;
	getParticipacoesBeneficiosById: GetByIdFn<readonly GetParticipacoesBeneficiosReturnType[]>;
	getParticipacoesSaldoById: GetByIdFn<GetParticipacoesSaldoReturnType>;
	getParticipacoesComposicaoById: GetByIdFn<GetParticipacoesComposicaoSaldoReturnType>;
	getExtratoContribuicaoById: GetByIdFn<readonly GetExtratoContribuicaoReturnType[], GetExtratoContribuicaoArgs>;
	getParticipacoesSaldoExtratoById: GetByIdFn<GetParticipacoesSaldoExtratoReturnType, GetParticipacoesSaldoExtratoArgs>;
	postCalculoInstitutosLegais: SendByIdFn<
		PostCalculoInstitutosLegaisPayloadType,
		PostCalculoInstitutosLegaisReturnType[]
	>;
	getDadosSimulacaoBeneficio: GetByIdFn<GetDadosSimulacaoBeneficioReturnType, GetDadosSimulacaoBeneficioArgs>;
	postRequerimentoBeneficio: SendByIdFn<PostRequerimentoBeneficioPayloadType>;
	/**
	 * `GET` na rota de Beneficiários.
	 *
	 * @param id Identificador do usuário
	 * @returns Dados pessoais do usuário
	 */
	getBeneficiariosById: GetByIdFn<readonly GetBeneficiariosReturnType[]>;
	/**
	 * `PUT` na rota de Beneficiários.
	 *
	 * @param id Identificador do usuário
	 * @param payload Payload completo
	 * @returns Lista de beneficiários
	 */
	putBeneficiariosById: SendByIdFn<readonly GetBeneficiariosReturnType[]>;

	getMinhaRentabilidadeById: GetByIdFn<GetMinhaRentabilidadeReturnType>;
	getEmprestimoById: GetByIdFn<GetEmprestimoReturnType>;
	getComposicaoSaldoById: GetByIdFn<GetComposicaoSaldoReturnType>;
	getNotificacoes: GetFn<readonly GetNotificacoesReturnType[]>;

	/**
	 * `GET` na rota de Dados Pessoais, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @returns Dados pessoais do usuário
	 */
	getParticipacoesDadosPessoaisById: GetByIdFn<GetParticipacoesDadosPessoaisReturnType>;
	postParticipacoesDadosPessoaisById: SendByIdFn<PostParticipacoesDadosPessoaisPayloadType>;

	/**
	 * `GET` na rota de Contas Bancárias, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @returns Contas bancárias do usuário
	 */
	getParticipacoesDadosContaBancariaById: GetByIdFn<readonly GetParticipacoesDadosContaBancariaReturnType[]>;
	postParticipacoesDadosContaBancaria: SendByIdFn<readonly PostParticipacoesDadosContaBancariaPayloadType[]>;

	/**
	 * `GET` na rota de Contato, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @returns Dados de Contato do usuário
	 */
	getParticipacoesDadosContatoById: GetByIdFn<GetParticipacoesDadosContatoReturnType>;
	postParticipacoesDadosContatoTelefone: SendByIdFn<readonly PostParticipacoesDadosContatoTelefonePayloadType[]>;
	postParticipacoesDadosContatoEndereco: SendByIdFn<readonly PostParticipacoesDadosContatoEnderecoPayloadType[]>;
	postParticipacoesDadosContatoEmail: SendByIdFn<readonly PostParticipacoesDadosContatoEmailPayloadType[]>;

	/**
	 * `PUT` na rota de Contato, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @param payload Payload completo
	 * @returns Dados de contato atualizados
	 */
	putParticipacoesContatoById: SendByIdFn<GetParticipacoesDadosContatoReturnType>;

	/**
	 * `GET` na rota de Planos, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @returns Dados relacionados ao Plano do usuário
	 */
	getParticipacoesDadosPlanosById: GetByIdFn<GetParticipacoesDadosPlanosReturnType>;
	/**
	 * `PUT` na rota de Planos, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @param payload Payload completo
	 * @returns Dados de plano atualizados
	 */
	putParticipacoesDadosPlanosById: SendByIdFn<GetParticipacoesDadosPlanosReturnType>;

	/**
	 * `GET` na rota de PPE, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @returns Dados relacionados ao forms de PPE
	 */
	getParticipacoesPpeById: GetByIdFn<GetParticipacoesPpeReturnType>;
	/**
	 * `PUT` na rota de PPE, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @param payload Payload completo
	 * @returns Dados de PPE atualizados
	 */
	putParticipacoesPpeById: SendByIdFn<GetParticipacoesPpeReturnType>;

	/**
	 * `GET` na rota de US Person, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @returns Dados relacionados ao forms de US Person
	 */
	getParticipacoesUsPersonById: GetByIdFn<GetParticipacoesUsPersonReturnType>;
	/**
	 * `PUT` na rota de US Person, pra tela de Dados Cadastrais.
	 *
	 * @param id Identificador do usuário
	 * @param payload Payload completo
	 * @returns Dados de US Person atualizados
	 */
	putParticipacoesUsPersonById: SendByIdFn<GetParticipacoesUsPersonReturnType>;

	getInformacoesTipoContaBancaria: GetFn<readonly GetInformacoesTipoContaBancariaReturnType[]>;
	getInformacoesTipoSexo: GetFn<readonly GetInformacoesTipoSexoReturnType[]>;
	getInformacoesTipoEstadoCivil: GetFn<readonly GetInformacoesTipoEstadoCivilReturnType[]>;
}

/**
 * Classe que contém os métodos relacionados à API do portal do participante.
 *
 * Por padrão, o nome dos métodos deve iniciar com o verbo HTTP que será chamado (get, post, put, etc.).
 */
export class ParticipanteApi extends BaseApi implements IParticipanteApi {
	// Participações
	// Beneficiários
	public getBeneficiariosById: GetByIdFn<readonly GetBeneficiariosReturnType[]> = async (id, params) => {
		return await this.getRequest<readonly GetBeneficiariosReturnType[]>(`/api/participacoes/${id}/dados/beneficiarios`, {
			params,
		});
	};
	public putBeneficiariosById: SendByIdFn<readonly GetBeneficiariosReturnType[]> = async (id, payload) => {
		return await this.putRequest(`/api/participacoes/${id}/beneficiarios`, payload);
	};

	// TODO - Conferir essa rota
	public getMinhaRentabilidadeById: GetByIdFn<GetMinhaRentabilidadeReturnType> = async () => {
		return await this.getRequest<GetMinhaRentabilidadeReturnType>(`/api/participacoes/minha-rentabilidade`);
	};

	// TODO - Conferir essa rota
	public getEmprestimoById: GetByIdFn<GetEmprestimoReturnType> = async () => {
		return await this.getRequest<GetEmprestimoReturnType>(`/api/participacoes/emprestimo`);
	};

	// TODO - Conferir essa rota
	public getComposicaoSaldoById: GetByIdFn<GetComposicaoSaldoReturnType> = async () => {
		return await this.getRequest<GetComposicaoSaldoReturnType>(`/api/participacoes/composicao-saldo`);
	};

	public getParticipacoesById: GetByIdFn<GetParticipacoesReturnType> = async (id, params) => {
		return await this.getRequest<GetParticipacoesReturnType>(`/api/participacoes/${id}`, { params });
	};

	public getParticipacoesBeneficiosById: GetByIdFn<readonly GetParticipacoesBeneficiosReturnType[]> = async (
		id,
		params,
	) => {
		return await this.getRequest<readonly GetParticipacoesBeneficiosReturnType[]>(
			`/api/participacoes/${id}/beneficios`,
			{ params },
		);
	};

	public getParticipacoesSaldoById: GetByIdFn<GetParticipacoesSaldoReturnType> = async (id, params) => {
		return await this.getRequest<GetParticipacoesSaldoReturnType>(`/api/participacoes/${id}/saldo`, { params });
	};

	public getParticipacoesComposicaoById: GetByIdFn<GetParticipacoesComposicaoSaldoReturnType> = async (id, params) => {
		return await this.getRequest<GetParticipacoesComposicaoSaldoReturnType>(
			`/api/participacoes/${id}/saldo/composicao`,
			{ params },
		);
	};

	// Dados pessoais
	public getParticipacoesDadosPessoaisById: GetByIdFn<GetParticipacoesDadosPessoaisReturnType> = async (id, params) => {
		return await this.getRequest(`/api/participacoes/${id}/dados/pessoais`, { params });
	};
	public postParticipacoesDadosPessoaisById: SendByIdFn<PostParticipacoesDadosPessoaisPayloadType> = async (
		id,
		payload,
	) => {
		return await this.postRequest(`/api/participacoes/${id}/dados/pessoais`, payload);
	};

	// Conta bancária
	public getParticipacoesDadosContaBancariaById: GetByIdFn<readonly GetParticipacoesDadosContaBancariaReturnType[]> =
		async (id, params) => {
			return await this.getRequest(`/api/participacoes/${id}/dados/conta-bancaria`, { params });
		};
	public postParticipacoesDadosContaBancaria: SendByIdFn<readonly PostParticipacoesDadosContaBancariaPayloadType[]> =
		async (id, payload) => {
			return await this.postRequest(`/api/participacoes/${id}/dados/conta-bancaria`, payload);
		};

	// Contato
	public getParticipacoesDadosContatoById: GetByIdFn<GetParticipacoesDadosContatoReturnType> = async (id, params) => {
		return await this.getRequest<GetParticipacoesDadosContatoReturnType>(`/api/participacoes/${id}/dados/contato`, {
			params,
		});
	};

	public postParticipacoesDadosContatoTelefone: SendByIdFn<
		readonly PostParticipacoesDadosContatoTelefonePayloadType[]
	> = async (id, payload) => {
		return await this.postRequest(`/api/participacoes/${id}/dados/contato/telefone`, payload);
	};

	public postParticipacoesDadosContatoEndereco: SendByIdFn<
		readonly PostParticipacoesDadosContatoEnderecoPayloadType[]
	> = async (id, payload) => {
		return await this.postRequest(`/api/participacoes/${id}/dados/contato/endereco`, payload);
	};
	public postParticipacoesDadosContatoEmail: SendByIdFn<readonly PostParticipacoesDadosContatoEmailPayloadType[]> =
		async (id, payload) => {
			return await this.postRequest(`/api/participacoes/${id}/dados/contato/email`, payload);
		};

	public putParticipacoesContatoById: SendByIdFn<GetParticipacoesDadosContatoReturnType> = async (id, payload) => {
		return await this.putRequest<GetParticipacoesDadosContatoReturnType>(`/api/participacoes/${id}/contato`, payload);
	};

	// Dados planos
	public getParticipacoesDadosPlanosById: GetByIdFn<GetParticipacoesDadosPlanosReturnType> = async (id, params) => {
		return await this.getRequest<GetParticipacoesDadosPlanosReturnType>(`/api/participacoes/${id}/dados-planos`, {
			params,
		});
	};
	public putParticipacoesDadosPlanosById: SendByIdFn<GetParticipacoesDadosPlanosReturnType> = async (id, payload) => {
		return await this.putRequest(`/api/participacoes/${id}/dados-planos`, payload);
	};

	// PPE
	public getParticipacoesPpeById: GetByIdFn<GetParticipacoesPpeReturnType> = async () => {
		throw new Error('Método sem implementação!');

		// TODO - Implementar na API
		// return await this.getRequest<GetParticipacoesPpeReturnType>(`rota`);
	};
	public putParticipacoesPpeById: SendByIdFn<GetParticipacoesPpeReturnType> = async () => {
		throw new Error('Método sem implementação!');

		// TODO - Implementar na API
		// return this.putRequest<GetParticipacoesPpeReturnType>(`rota`, payload);
	};

	// US Person
	public getParticipacoesUsPersonById: GetByIdFn<GetParticipacoesUsPersonReturnType> = async () => {
		throw new Error('Método sem implementação!');

		// TODO - Implementar na API
		// return await this.getRequest<GetParticipacoesPpeReturnType>(`rota`);
	};
	public putParticipacoesUsPersonById: SendByIdFn<GetParticipacoesUsPersonReturnType> = async () => {
		throw new Error('Método sem implementação!');

		// TODO - Implementar na API
		// return this.putRequest<GetParticipacoesPpeReturnType>(`rota`, payload);
	};

	// Extrato
	public getParticipacoesSaldoExtratoById: GetByIdFn<
		GetParticipacoesSaldoExtratoReturnType,
		GetParticipacoesSaldoExtratoArgs
	> = async (id, params) => {
		return await this.getRequest<GetParticipacoesSaldoExtratoReturnType>(`/api/participacoes/${id}/saldo/extrato`, {
			params,
		});
	};

	// Contribuições
	public getTotaisContribuicoesById: GetByIdFn<GetTotaisContribuicoesReturnType> = async (id, params) => {
		return await this.getRequest<GetTotaisContribuicoesReturnType>(`/api/contribuicoes/totais/${id}`, { params });
	};

	public getContribuicoesById: GetByIdFn<GetContribuicoesReturnType> = async (id, params) => {
		return await this.getRequest<GetContribuicoesReturnType>(`/api/contribuicoes/${id}`, { params });
	};

	public getHistoricoMensalContribuicoesById: GetByIdFn<GetHistoricoMensalContribuicoesReturnType> = async (
		id,
		params,
	) => {
		return await this.getRequest<GetHistoricoMensalContribuicoesReturnType>(
			`/api/contribuicoes/${id}/historico-mensal`,
			{ params },
		);
	};

	public getContribuicoesAlteracaoCusteioContasById: GetByIdFn<GetContribuicoesAlteracaoCusteioContasReturnType> =
		async (id, params) => {
			return await this.getRequest(`/api/contribuicoes/${id}/alteracao-custeio/contas`, { params });
		};
	public postContribuicoesAlteracaoCusteioContasById: SendByIdFn<PostContribuicoesAlteracaoCusteioContasPayloadType> =
		async (id, payload) => {
			return await this.postRequest(`/api/contribuicoes/${id}/alteracao-custeio/contas`, payload);
		};

	public getContribuicoesAlteracaoCusteioEstimativaAcumuloById: GetByIdFn<GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType> =
		async (id, params) => {
			return await this.getRequest(`/api/contribuicoes/${id}/alteracao-custeio/estimativa-acumulo`, { params });
		};

	public getContribuicoesSimulacoesEstimativaAcumuloById: GetByIdFn<GetContribuicoesSimulacoesEstimativaAcumuloReturnType> =
		async (id, params) => {
			return await this.getRequest(`/api/contribuicoes/${id}/dashboard/simulacoes-estimativa-acumulo`, { params });
		};

	public getContribuicoesAlteracaoCusteioBeneficioFiscalById: GetByIdFn<GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType> =
		async (id, params) => {
			return await this.getRequest(`/api/contribuicoes/${id}/alteracao-custeio/beneficio-fiscal`, { params });
		};

	// Benefícios
	public getBeneficiosSaldosById: GetByIdFn<GetBeneficiosSaldosReturnType> = async (id, params) => {
		return await this.getRequest<GetBeneficiosSaldosReturnType>(`/api/beneficios/saldos/${id}`, { params });
	};

	public getBeneficiosContrachequeById: GetByIdFn<readonly GetBeneficiosContrachequeReturnType[]> = async (
		id,
		params,
	) => {
		return await this.getRequest<readonly GetBeneficiosContrachequeReturnType[]>(
			`/api/beneficios/${id}/contra-cheque`,
			{ params },
		);
	};

	public getBeneficiosRendasById: GetByIdFn<readonly GetBeneFiciosRendasReturnType[]> = async (id, params) => {
		return await this.getRequest(`/api/beneficios/${id}/rendas`, { params });
	};

	public getBeneficiosSaldoDetalhadoById: GetByIdFn<GetBeneficiosSaldoDetalhadoReturnType> = async (id, params) => {
		return await this.getRequest<GetBeneficiosSaldoDetalhadoReturnType>(`/api/beneficios/saldodetalhado/${id}`, {
			params,
		});
	};

	public getFichaFinanceiraAssistidoById: GetByIdFn<
		readonly GetFichaFinanceiraAssistidoReturnType[],
		GetFichaFinanceiraAssistidoArgs
	> = async (id, params) => {
		return await this.getRequest<readonly GetFichaFinanceiraAssistidoReturnType[]>(
			`/api/beneficios/${id}/assistido/ficha-financeira`,
			{ params },
		);
	};

	// Header
	public getParticipacoesPlanosById: GetByNullableIdFn<readonly GetParticipacoesPlanosReturnType[]> = async (
		id,
		params,
	) => {
		const result = await this.getRequest<readonly GetParticipacoesPlanosReturnType[]>(
			id ? `/api/participacoes/${id}/planos` : `/api/participacoes/planos`,
			{ params },
		);

		const nomePlanos = result.map((p) => p.plano);

		result.forEach((plano) => {
			const isParticipacaoDuplicada = result.some((p) => p.participacaoId === plano.participacaoId);
			const isPlanoDuplicado = result.some((p) => nomePlanos.includes(p.plano));

			if (isParticipacaoDuplicada || isPlanoDuplicado)
				plano.plano = `${plano.plano} - Adesão ${new Date(plano.dataAdesao).toLocaleDateString()}`;
		});

		return result;
	};

	// Extrato Contribuicao
	public getExtratoContribuicaoById: GetByIdFn<
		readonly GetExtratoContribuicaoReturnType[],
		GetExtratoContribuicaoArgs
	> = async (id, params) => {
		const urlBase = `/api/participacoes/${id}/extrato-movimentos`;

		return await this.getRequest<GetExtratoContribuicaoReturnType[]>(urlBase, {
			params: {
				filtro: params?.filtro,
				dataInicio: params?.dataInicio?.toISOString().split('T')[0],
				dataFim: params?.dataFim?.toISOString().split('T')[0],
			},
		});
	};

	// TODO - Implementar essa função corretamente
	public getNotificacoes: GetFn<readonly GetNotificacoesReturnType[]> = async (params) => {
		return this.getRequest<GetNotificacoesReturnType[]>('/api/notificacoes', { params });
	};

	// Cálculo de Benefício
	public postCalculoInstitutosLegais: SendByIdFn<
		PostCalculoInstitutosLegaisPayloadType,
		PostCalculoInstitutosLegaisReturnType[]
	> = async (id, payload) => {
		const urlBase = `/api/beneficios/participacao/${id}/calculos/instituto-legal`;

		return await this.postRequest(urlBase, payload);
	};

	public getDadosSimulacaoBeneficio: GetByIdFn<GetDadosSimulacaoBeneficioReturnType, GetDadosSimulacaoBeneficioArgs> =
		async (id, params) => {
			const urlBase = `/api/beneficios/participacao/${id}/calculos/resultado`;

			return await this.getRequest<GetDadosSimulacaoBeneficioReturnType>(urlBase, {
				params: { tipoBeneficioId: params?.tipoBeneficioId },
			});
		};

	public postRequerimentoBeneficio: SendByIdFn<PostRequerimentoBeneficioPayloadType> = async (id, payload) => {
		const urlBase = `/api/beneficios/participacao/${id}/requerimento`;

		return await this.postRequest(urlBase, payload);
	};

	// Informações
	public getInformacoesTipoContaBancaria: GetFn<readonly GetInformacoesTipoContaBancariaReturnType[]> = async (
		params,
	) => {
		return this.getRequest('/api/informacoes/tipo/conta-bancaria', { params });
	};

	public getInformacoesTipoSexo: GetFn<readonly GetInformacoesTipoSexoReturnType[]> = async (params) => {
		return this.getRequest('/api/informacoes/tipo/sexo', { params });
	};

	public getInformacoesTipoEstadoCivil: GetFn<readonly GetInformacoesTipoEstadoCivilReturnType[]> = async (params) => {
		return this.getRequest('/api/informacoes/tipo/estado-civil', { params });
	};
}
