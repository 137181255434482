import { GetByIdFn, GetByNullableIdFn, GetFn, SendByIdFn } from 'api';
import {
	ArrayItem,
	DateFormatter,
	GrauDeParentescoEnum,
	PerfilInvestimentoEnum,
	Range,
	RegimeTributacaoEnum,
	SituacaoParticipacaoEnum,
	TipoBeneficioEnum,
	TipoContribuicaoEnum,
	TipoEmailEnum,
	TipoEnderecoEnum,
	TipoParticipanteEnum,
	TipoTelefoneEnum,
} from 'lib';

import {
	GetBeneficiariosReturnType,
	GetBeneficiosContrachequeReturnType,
	GetBeneFiciosRendasReturnType,
	GetBeneficiosSaldoDetalhadoReturnType,
	GetBeneficiosSaldosReturnType,
	GetComposicaoSaldoReturnType,
	GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType,
	GetContribuicoesAlteracaoCusteioContasReturnType,
	GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType,
	GetContribuicoesReturnType,
	GetContribuicoesSimulacoesEstimativaAcumuloReturnType,
	GetDadosSimulacaoBeneficioReturnType,
	GetEmprestimoReturnType,
	GetExtratoContribuicaoArgs,
	GetExtratoContribuicaoReturnType,
	GetFichaFinanceiraAssistidoArgs,
	GetFichaFinanceiraAssistidoReturnType,
	GetHistoricoMensalContribuicoesReturnType,
	GetInformacoesTipoContaBancariaReturnType,
	GetInformacoesTipoEstadoCivilReturnType,
	GetInformacoesTipoSexoReturnType,
	GetMinhaRentabilidadeReturnType,
	GetNotificacoesReturnType,
	GetParticipacoesBeneficiosReturnType,
	GetParticipacoesComposicaoSaldoReturnType,
	GetParticipacoesDadosContaBancariaReturnType,
	GetParticipacoesDadosContatoReturnType,
	GetParticipacoesDadosPessoaisReturnType,
	GetParticipacoesDadosPlanosReturnType,
	GetParticipacoesPlanosReturnType,
	GetParticipacoesPpeReturnType,
	GetParticipacoesReturnType,
	GetParticipacoesSaldoExtratoArgs,
	GetParticipacoesSaldoExtratoReturnType,
	GetParticipacoesSaldoReturnType,
	GetParticipacoesUsPersonReturnType,
	GetTotaisContribuicoesReturnType,
	IParticipanteApi,
	PostCalculoInstitutosLegaisReturnType,
	PostContribuicoesAlteracaoCusteioContasPayloadType,
	PostParticipacoesDadosContaBancariaPayloadType,
	PostParticipacoesDadosContatoEmailPayloadType,
	PostParticipacoesDadosContatoEnderecoPayloadType,
	PostParticipacoesDadosContatoTelefonePayloadType,
	PostParticipacoesDadosPessoaisPayloadType,
	PostRequerimentoBeneficioPayloadType,
} from '../ParticipanteApi';
import { BaseApi } from './BaseApi';

// Mock data
export const mockGetTotaisContribuicoesSuccessData: GetTotaisContribuicoesReturnType = {
	totalContribuicaoParticipanteUltimoMes: 300,
	totalContribuicaoPatrocinadoraUltimoMes: 200,
	totalContribuicaoUltimoMes: 500,
	totalContribuicoesOutrosPlanos: 3425,
};

export const mockGetContribuicoesSuccessData: GetContribuicoesReturnType = {
	totalParticipante: 4000,
	contribuicoesParticipante: [
		{ nome: 'Básica', percentual: 0.5, valor: 1000 },
		{ nome: 'Adicional', percentual: 0.5, valor: 1000 },
		{ nome: 'Voluntária', percentual: 0.5, valor: 1000 },
		{ nome: 'Portabilidade', percentual: 0.5, valor: 1000 },
	],

	totalPatrocinadora: 3000,
	contribuicoesPatrocinadora: [
		{ nome: 'Normal', percentual: 0.6, valor: 1500 },
		{ nome: 'Complementar', percentual: 0.6, valor: 1500 },
	],
};

export const mockGetHistoricoMensalContribuicoesSuccessData: GetHistoricoMensalContribuicoesReturnType = {
	contribuicoesParticipante: [
		{ mes: 1, valor: 10000.0 },
		{ mes: 2, valor: 11000.0 },
		{ mes: 3, valor: 12000.0 },
		{ mes: 4, valor: 12000.0 },
		{ mes: 5, valor: 13000.0 },
		{ mes: 6, valor: 14000.0 },
		{ mes: 7, valor: 14000.0 },
		{ mes: 8, valor: 15000.0 },
		{ mes: 9, valor: 16000.0 },
		{ mes: 10, valor: 17000.0 },
		{ mes: 11, valor: 18000.0 },
		{ mes: 12, valor: 19000.0 },
	],
	contribuicoesPatrocinadora: [
		{ mes: 1, valor: 5000.0 },
		{ mes: 2, valor: 5000.0 },
		{ mes: 3, valor: 5500.0 },
		{ mes: 4, valor: 5500.0 },
		{ mes: 5, valor: 6000.0 },
		{ mes: 6, valor: 6000.0 },
		{ mes: 7, valor: 6500.0 },
		{ mes: 8, valor: 6500.0 },
		{ mes: 9, valor: 7000.0 },
		{ mes: 10, valor: 7000.0 },
		{ mes: 11, valor: 7500.0 },
		{ mes: 12, valor: 7500.0 },
	],
};

export const mockGetContribuicoesAlteracaoCusteioPlanoBasicoSuccessData: GetContribuicoesAlteracaoCusteioContasReturnType =
	{
		salarioParticipacao: 10000,

		configsContas: [
			{
				contribuicaoPlanoId: 1,
				contribuicaoPlano: 'Conta básica',
				contribuicaoPlanoCodigo: TipoContribuicaoEnum.Basica,
				percentualContrapartida: 0.5,
				percentualAtual: 0.1, // Independente pra cada régua
				valorAtual: 10000 * 0.1, // Calculado na API
				contrapartidaAtual: 10000 * 0.1 * 0.5, // Calculado na API
				contribuicaoTotalNoMes: 10000 * 0.1 + 10000 * 0.1 * 0.5, // Calculado na API

				// TODO - Tratar múltiplas réguas quando implementar CargillPrev
				// TODO - Trazer percentual atual dentro dessa lista caso cada régua seja independente
				configsContribucoes: [{ percentualMinimo: 0, percentualMaximo: 0.1, multiploPercentual: 0.01 }],
			},

			{
				contribuicaoPlanoId: 2,

				contribuicaoPlano: 'Conta adicional',
				contribuicaoPlanoCodigo: TipoContribuicaoEnum.Adicional,
				percentualContrapartida: 0,
				percentualAtual: 0.04,
				valorAtual: 10000 * 0.04, // Calculado na API
				contrapartidaAtual: 0, // Calculado na API
				contribuicaoTotalNoMes: 10000 * 0.04, // Calculado na API

				configsContribucoes: [{ percentualMinimo: 0.0, percentualMaximo: 0.1, multiploPercentual: 0.02 }],
			},

			{
				contribuicaoPlanoId: 3,

				contribuicaoPlano: 'Conta voluntária',
				contribuicaoPlanoCodigo: TipoContribuicaoEnum.Voluntária,
				percentualContrapartida: 0,
				percentualAtual: 0.3,
				valorAtual: 10000 * 0.3, // Calculado na API
				contrapartidaAtual: 0, // Calculado na API
				contribuicaoTotalNoMes: 10000 * 0.3, // Calculado na API

				configsContribucoes: [{ percentualMinimo: 0.05, percentualMaximo: 0.5, multiploPercentual: 0.05 }],
			},
		],
	};

export const mockGetContribuicoesAlteracaoCusteioEstimativaAcumuloSuccessData: GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType =
	{
		parametrosCalculo: {
			saldoInicial: 50000,
			idadeAtual: 30,
			crescimentoRealContribuicoes: 0.02,
			retornoInvestimentoAnual: 0.05,
			idadeProjetada: 60,
		},

		estimativaAcumuloAtual: 20000,
	};

export const mockGetContribuicoesSimulacoesEstimativaAcumuloSuccessData: GetContribuicoesSimulacoesEstimativaAcumuloReturnType =
	{
		cenarioFavoravel: 150000,
		melhorEstimativa: 500000,
		cenarioDesfavoravel: 1.99,
	};

export const mockGetContribuicoesAlteracaoCusteioBeneficioFiscalSuccessData: GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType =
	{
		parametrosCalculo: {
			salarioParticipacao: 10000,
			mesAtual: 6,
			fatorBeneficio: 0.12,
		},

		beneficioFiscalAtual: 2500,
	};

export const mockGetBeneficiosSaldosSuccessData: GetBeneficiosSaldosReturnType = {
	saldoTotal: { valorTotal: 20000, valorResgate: 12000, percentualResgate: 0.6 },
	saldoParticipante: { valorTotal: 10000, valorResgate: 5000, percentualResgate: 0.5 },
	saldoPatrocinadora: { valorTotal: 7500, valorResgate: 1875, percentualResgate: 0.25 },
	saldoPortabilidade: { valorTotal: 2500, valorResgate: 250, percentualResgate: 0.1 },

	emprestimo: {
		saldoIsento: 500,

		saque: { valor: 5000, percentual: 0.05 },
		saldos: {
			saldo10: 1000,
			saldo15: 1500,
			saldo20: 2000,
			saldo25: 2500,
			saldo30: 3000,
			saldo35: 3500,
		},
	},
};

export const mockGetParticipacoesSuccessData: GetParticipacoesReturnType = {
	salario: 10000,
	perfisAtivos: ['Arrojado'],
};

export const mockGetParticipacoesPlanosSuccessData: GetParticipacoesPlanosReturnType[] = [
	{
		participacaoId: 1,
		nome: 'Fulano Mockado da Silva',
		plano: 'Plano CD - Adesão 10/02/2008',
		matricula: 2000,
		dataAdesao: '2008-02-10T00:00:00',
		dataReferencia: '2023-07-01T00:00:00',
		status: 'Vinculado à Patrocinadora',
		perfil: 'Curto Prazo',
		tipoParticipante: TipoParticipanteEnum.AtivoComEmprestimo,
	},

	{
		participacaoId: 2,
		nome: 'Fulano Mockado da Silva',
		plano: 'Plano CD - Adesão 10/01/2008',
		matricula: 1000,
		dataAdesao: '2008-01-10T00:00:00',
		dataReferencia: '2023-07-01T00:00:00',
		status: 'Em Benefício',
		perfil: 'Curto Prazo',
		tipoParticipante: TipoParticipanteEnum.PensionistaProgressivo,
	},

	{
		participacaoId: 3,
		nome: 'Fulano Mockado da Silva',
		plano: 'Plano BD - Adesão 10/01/2008',
		matricula: 1000,
		dataAdesao: '2008-01-10T00:00:00',
		dataReferencia: '2023-07-01T00:00:00',
		status: 'BPD',
		perfil: 'Curto Prazo',
		tipoParticipante: TipoParticipanteEnum.BPDComEmprestimo,
	},
];

const getMockGetFichaFinanceiraDataByMes = (
	mes: Range<1, 13>,
): ArrayItem<GetFichaFinanceiraAssistidoReturnType['itensMensais']> => {
	return {
		mes,
		itensFolhaBeneficio: [
			{ nome: 'Total de descontos', valor: -800 },
			{ nome: 'Renda Mensal', valor: 1000 },
			{ nome: 'Descontos de empréstimo - prestação', valor: -500 },
			{ nome: 'Imposto de renda', valor: -200 },
			{ nome: 'Total de proventos', valor: 1300 },
			{ nome: 'Proventos', valor: 300 },
			{ nome: 'Líquido', valor: 500 },
		],
	};
};

export const getMockGetFichaFinanceiraDataByAno = (ano: number): GetFichaFinanceiraAssistidoReturnType => {
	return {
		ano,
		itensMensais: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((mes) =>
			getMockGetFichaFinanceiraDataByMes(mes as Range<1, 13>),
		),
	};
};

export const mockGetFichaFinanceiraAssistidoSuccessData: GetFichaFinanceiraAssistidoReturnType[] = [
	getMockGetFichaFinanceiraDataByAno(2023),
];

export const mockGetParticipacoesBeneficiosSuccessData: GetParticipacoesBeneficiosReturnType[] = [
	{
		idBeneficio: 1,
		nome: 'Nome do benefício 1',
		dataInicio: new Date().toISOString(),
		dataInicioPagamento: new Date().toISOString(),
	},

	{
		idBeneficio: 2,
		nome: 'Nome do benefício 2',
		dataInicio: new Date().toISOString(),
		dataInicioPagamento: new Date().toISOString(),
	},
];

export const mockGetExtratoContribuicaoByIdAndFilterSuccessData: GetExtratoContribuicaoReturnType[] = [
	{
		ano: new Date().getFullYear(),
		mes: 12,
		movimentos: [
			{
				data: new Date('2023-12-01T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 352.2,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-12-02T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 285,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-12-03T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 352.2,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-12-04T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 60.2,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
		],
	},

	{
		ano: new Date().getFullYear(),
		mes: 11,
		movimentos: [
			{
				data: new Date('2023-11-01T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-11-02T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 285,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-11-03T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
		],
	},

	{
		ano: new Date().getFullYear(),
		mes: 10,
		movimentos: [
			{
				data: new Date('2023-10-01T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-02T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 285,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-03T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
		],
	},

	{
		ano: new Date().getFullYear(),
		mes: 9,
		movimentos: [
			{
				data: new Date('2023-10-01T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-02T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 285,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-03T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
		],
	},

	{
		ano: new Date().getFullYear(),
		mes: 8,
		movimentos: [
			{
				data: new Date('2023-10-01T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-02T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 285,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-03T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
		],
	},

	{
		ano: new Date().getFullYear(),
		mes: 7,
		movimentos: [
			{
				data: new Date('2023-10-01T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-02T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 285,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-03T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
		],
	},

	{
		ano: new Date().getFullYear(),
		mes: 6,
		movimentos: [
			{
				data: new Date('2023-10-01T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-02T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Participante',
				evento: 'Saída',
				valor: 285,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
			{
				data: new Date('2023-10-03T00:00:00'),
				indice: 'Prata',
				conta: 'Básica',
				tipo: 'Patrocinadora',
				evento: 'Saída',
				valor: 323.7,
				valorQuota: 12.303838,
				quotas: 1.0379,
			},
		],
	},
];

export const mockGetParticipacoesSaldoSuccessData: GetParticipacoesSaldoReturnType = {
	saldoTotal: 1456279.78,
	saldosParticipante: [
		{
			tipo: 'Remuneração Variável',
			valor: 8178.83,
			percentual: 0.01,
		},
		{
			tipo: 'Esporádica 13o',
			valor: 3099.47,
			percentual: 0,
		},
		{
			tipo: 'Normal',
			valor: 630318.95,
			percentual: 0.91,
		},
		{
			tipo: 'Voluntária',
			valor: 48368.37,
			percentual: 0.07,
		},
	],
	saldosPatrocinadora: [
		{
			tipo: 'Básica Patroc',
			valor: 123167.18,
			percentual: 0.16,
		},
		{
			tipo: 'Variável Patroc',
			valor: 69592.07,
			percentual: 0.09,
		},
		{
			tipo: 'Complementar',
			valor: 560726.88,
			percentual: 0.73,
		},
		{
			tipo: 'Variável 13° Patroc',
			valor: 4649.2,
			percentual: 0.01,
		},
		{
			tipo: 'Variável PPR Patroc',
			valor: 8178.83,
			percentual: 0.01,
		},
	],
	historicoMensal: [
		{
			mes: 1,
			totalParticipante: 579627.37,
			totalPatrocinadora: 648102.71,
		},
		{
			mes: 2,
			totalParticipante: 591210.32,
			totalPatrocinadora: 660489.96,
		},
		{
			mes: 3,
			totalParticipante: 614955.58,
			totalPatrocinadora: 686458.21,
		},
		{
			mes: 4,
			totalParticipante: 634100.63,
			totalPatrocinadora: 707274.13,
		},
		{
			mes: 5,
			totalParticipante: 661943.21,
			totalPatrocinadora: 737778.67,
		},
		{
			mes: 6,
			totalParticipante: 683895.68,
			totalPatrocinadora: 761699.1,
		},
		{
			mes: 7,
			totalParticipante: 692861.61,
			totalPatrocinadora: 771141.82,
		},
		{
			mes: 8,
			totalParticipante: 693767.27,
			totalPatrocinadora: 771610.33,
		},
		{
			mes: 9,
			totalParticipante: 689754.32,
			totalPatrocinadora: 766611.37,
		},
		{
			mes: 10,
			totalParticipante: 689965.62,
			totalPatrocinadora: 766314.16,
		},
	],
};

export const mockGetParticipacoesComposicaoSaldoSuccessData: GetParticipacoesComposicaoSaldoReturnType = {
	outros: 0,
	saldoParticipante: 500,
	saldoPatrocinadora: 0,
	saldoPortabilidade: 300,
	rentabilidade: 0.15,
	valorSaida: -200,
	saldoTotal: 600,
};

export const mockGetBeneficiosContrachequeSuccessData: GetBeneficiosContrachequeReturnType[] = [
	{
		beneficioId: mockGetParticipacoesBeneficiosSuccessData[0].idBeneficio,
		pagamentoId: 1,
		descricaoPagamento: 'Pagamento 01',
		tipoBeneficio: TipoBeneficioEnum.AposentadoriaAntecipada,
		dataCompetencia: new Date().toISOString(),
		dataPagamento: new Date().toISOString(),

		totalProventos: 10000,
		totalDescontos: 1500,
		liquido: 8500,

		itemFolha: [
			{ descricao: 'Provento 01', valor: 4500 },
			{ descricao: 'Provento 02', valor: 5500 },

			{ descricao: 'Desconto 03', valor: -1000 },
			{ descricao: 'Desconto 03', valor: -500 },
		],
	},

	{
		beneficioId: mockGetParticipacoesBeneficiosSuccessData[0].idBeneficio,
		pagamentoId: 2,
		descricaoPagamento: 'Pagamento 02',
		tipoBeneficio: TipoBeneficioEnum.AposentadoriaNormal,
		dataCompetencia: new Date().toISOString(),
		dataPagamento: new Date().toISOString(),

		totalProventos: 10000,
		totalDescontos: 3500,
		liquido: 6500,

		itemFolha: [
			{ descricao: 'Provento 01', valor: 4500 },
			{ descricao: 'Provento 02', valor: 5500 },

			{ descricao: 'Desconto 01', valor: -1000 },
			{ descricao: 'Desconto 02', valor: -1000 },
			{ descricao: 'Desconto 03', valor: -1000 },
			{ descricao: 'Desconto 03', valor: -500 },
		],
	},

	{
		beneficioId: mockGetParticipacoesBeneficiosSuccessData[1].idBeneficio,
		pagamentoId: 1,
		descricaoPagamento: 'Entrada 01',
		tipoBeneficio: TipoBeneficioEnum.AuxilioDoenca,
		dataCompetencia: new Date().toISOString(),
		dataPagamento: new Date().toISOString(),

		totalProventos: 5000,
		totalDescontos: 1000,
		liquido: 4000,

		itemFolha: [
			{ descricao: 'Provento 01', valor: 4800 },
			{ descricao: 'Provento 02', valor: 200 },

			{ descricao: 'Desconto 01', valor: -1000 },
		],
	},
];

export const mockGetBeneficiosRendasSuccessData: GetBeneFiciosRendasReturnType[] = [
	{
		tipoBeneficio: 'Tipo Benefício 1',
		tipoRenda: 'Tipo Renda 1',
		dataInicio: new Date().toISOString(),
		dataInicioPagamento: new Date().toISOString(),
		dataFimPrevista: new Date().toISOString(),
	},

	{
		tipoBeneficio: 'Tipo Benefício 2',
		tipoRenda: 'Tipo Renda 2',
		dataInicio: new Date().toISOString(),
		dataInicioPagamento: new Date().toISOString(),
		dataFimPrevista: null,
	},
];

export const mockGetBeneficiosSaldoDetalhadoSuccessData: GetBeneficiosSaldoDetalhadoReturnType = {
	saldoParticipante: {
		contasPlano: [
			{
				contaPlano: 'Suplementar',
				percentualResgateContaPlano: 0,
				valorSaldo: 250,
				valorSaldoCotas: 25,
			},

			{
				contaPlano: 'Adicional',
				percentualResgateContaPlano: 0,
				valorSaldo: 250,
				valorSaldoCotas: 25,
			},

			{
				contaPlano: 'Portabilidade',
				percentualResgateContaPlano: 0,
				valorSaldo: 250,
				valorSaldoCotas: 25,
			},
		],
		percentualResgate: 0,
		valorTotal: 0,
	},
	saldoPatrocinadora: {
		contasPlano: [
			{
				contaPlano: 'Suplementar',
				percentualResgateContaPlano: 0,
				valorSaldo: 100,
				valorSaldoCotas: 10,
			},

			{
				contaPlano: 'Adicional',
				percentualResgateContaPlano: 0,
				valorSaldo: 200,
				valorSaldoCotas: 10,
			},
		],
		percentualResgate: 0,
		valorTotal: 0,
	},
	valorTotal: 0,
};

export const mockGetBeneficiariosSuccessData: GetBeneficiariosReturnType[] = [
	{
		key: 1,
		cpf: '32165498759',
		dataNascimento: DateFormatter.toDayjs().toISOString(),
		nome: 'Antonieta Orleans',
		parentesco: GrauDeParentescoEnum.Conjuge,
		percentualRecebimento: 0.25,
		total: 'R$ 3999,99',
		dependenteLegalSn: true,
		dependenteImpRendaSn: true,
		email: 'toninha@guilhotina.com',
		telefone: '(12) 3456-7890',
	},
	{
		key: 2,
		cpf: '98765412315',
		dataNascimento: DateFormatter.toDayjs().toISOString(),
		nome: 'Bernardo Silva',
		parentesco: GrauDeParentescoEnum.FilhoFilha,
		percentualRecebimento: 0.25,
		total: 'R$ 999,99',
		dependenteLegalSn: true,
		dependenteImpRendaSn: true,
		email: 'Bsilva@guilhotina.com',
		telefone: '(12) 3456-7890',
	},
	{
		key: 3,
		cpf: '12345678995',
		dataNascimento: DateFormatter.toDayjs().toISOString(),
		nome: 'Clara Oliveira',
		parentesco: GrauDeParentescoEnum.Outro,
		percentualRecebimento: 0.15,
		total: 'R$ 599,99',
		dependenteLegalSn: false,
		dependenteImpRendaSn: false,
		email: 'clara@gema.com',
		telefone: '(12) 3456-7890',
	},
	{
		key: 4,
		cpf: '22255577784',
		dataNascimento: DateFormatter.toDayjs().toISOString(),
		nome: 'Clara Oliveira',
		parentesco: GrauDeParentescoEnum.Outro,
		percentualRecebimento: 0.15,
		total: 'R$ 599,99',
		dependenteLegalSn: false,
		dependenteImpRendaSn: false,
		email: 'clara@gema.com',
		telefone: '(12) 3456-7890',
	},
];

export const mockGetMinhaRentabilidadeSuccessData: GetMinhaRentabilidadeReturnType = {
	perfil: 'Conservador',

	minhaRentabilidadeInfo: [
		{ key: 1, periodo: 'Mês', porcentagem: '20,00%', valorizacao: -1 },
		{ key: 2, periodo: 'Bimestre', porcentagem: '30,00%', valorizacao: 1 },
		{ key: 3, periodo: 'Trimestre', porcentagem: '10,00%', valorizacao: 1 },
		{ key: 4, periodo: 'Semestre', porcentagem: '20,00%', valorizacao: 1 },
		{ key: 5, periodo: 'Ano', porcentagem: '50,00%', valorizacao: 1 },
	],
};

export const mockGetEmprestimoSuccessData: GetEmprestimoReturnType = {
	meusEmprestimos: [
		{
			valorSolicitado: 5000,
			saldoDevedor: 4500,
			qtdParcelas: 12,
		},
		{
			valorSolicitado: 10000,
			saldoDevedor: 8000,
			qtdParcelas: 24,
		},
		{
			valorSolicitado: 15000,
			saldoDevedor: 12000,
			qtdParcelas: 36,
		},
	],
};

export const mockGetComposicaoSaldoSuccessData: GetComposicaoSaldoReturnType = {
	valorParticipante: 125000.5,
	valorPatrocinadora: 75000.75,
	rendimento: 30000.25,
	valorSaida: 40000.3,
	totalAcumulado: 270031.8,
};

export const mockGetNotificacoesSuccessData: GetNotificacoesReturnType[] = [
	{
		title: 'Notificação 01',
		icon: 'Error',
	},
	{
		title: 'Notificação 02 com descrição',
		new: true,
	},
	{
		title: 'Notificação 03',
	},
	{
		title: 'Notificação 04 com descrição',
		icon: 'Paid',
		new: true,
	},
	{
		title: 'Notificação 05 com ícone inválido',
		icon: 'ícone inválido',
	},
];

export const mockGetParticipacoesDadosPessoaisSuccessData: GetParticipacoesDadosPessoaisReturnType = {
	podeEditar: true,

	nome: 'Jose Luiz Gomes Talarico',
	cpf: '26125587720',
	nascimento: '1954-10-09T00:00:00',
	tipoSexo: 'Masculino',
	estadoCivil: 'Divorciado',
	nomeMae: 'Alzira Santos Talarico',
	nomePai: 'Jose Gomes Talarico',
	isResidenteExterior: false,
	isUsPerson: false,
	nacionalidade: 'Brasileira',
	naturalidade: 'Rio de Janeiro',
};

export const mockGetParticipacoesDadosContaBancariaSuccessData: GetParticipacoesDadosContaBancariaReturnType[] = [
	{
		podeEditar: true,

		banco: 'Banco 01',
		agencia: '3008',
		contaCorrente: '49581',
		digitoVerificadorContaCorrente: '1',
	},

	{
		podeEditar: true,

		banco: 'Banco 03',
		agencia: '1514',
		contaCorrente: '98701',
		digitoVerificadorContaCorrente: 'X',
	},
];

export const mockGetParticipacoesContatoSuccessData: GetParticipacoesDadosContatoReturnType = {
	podeEditar: true,

	telefones: [
		{
			id: 1,
			dataAtivacao: '2023-11-01T00:00:00',

			ddi: 55,
			ddd: 83,
			numero: 999895219,
			tipo: TipoTelefoneEnum.Celular,

			telefonePreferencialSn: true,
			whatsappSn: true,
			smsSn: true,
		},

		{
			id: 2,
			dataAtivacao: '2023-11-01T00:00:00',

			ddi: 55,
			ddd: 19,
			numero: 38888888,
			tipo: TipoTelefoneEnum.Residencial,

			telefonePreferencialSn: false,
			whatsappSn: true,
			smsSn: true,
		},
	],

	enderecos: [
		{
			id: 1,
			dataAtivacao: '2023-11-01T00:00:00',

			cep: '85960000',
			numero: '123',
			bairro: 'DONA LINDÚ',
			uf: 'PR',
			pais: 'BRASIL',
			endereco: 'RUA 01',
			complemento: '',
			municipio: 'MARINGÝ',
			tipo: TipoEnderecoEnum.Residencial,

			enderecoPreferencialSn: true,
		},
	],

	emails: [
		{
			id: 1,
			dataAtivacao: '2023-11-01T00:00:00',

			email: 'principal@example.com',
			tipo: TipoEmailEnum.Pessoal,

			emailPreferencialSn: false,
		},
		{
			id: 2,
			dataAtivacao: '2023-11-01T00:00:00',

			email: 'corporativo@example.com',
			tipo: TipoEmailEnum.Corporativo,

			emailPreferencialSn: true,
		},
	],
};

export const mockGetParticipacoesDadosPlanosSuccessData: GetParticipacoesDadosPlanosReturnType = {
	plano: 'Plano de Benefícios SarahPrev',
	regimeTributacao: RegimeTributacaoEnum.TabelaRegressiva,
	dataAdesaoPlano: '1997-01-01T00:00:00',
	tipoVinculoParticipacao: 'Em Benefício',
	perfilInvestimento: PerfilInvestimentoEnum.Conservador,
	opcaoPerfilInvestimento: PerfilInvestimentoEnum.Agressivo,
	situacaoParticipacao: SituacaoParticipacaoEnum.Falecido,
	empregador: 'ASSOCIAÇÃO DAS PIONEIRAS SOCIAIS',
	salario: 0.01,
	dataAdmissao: '1994-06-20T00:00:00',
	matricula: 202367,
	lotacao: '-',
	municipio: 'Brasilia',
	situacaoEmprego: 'Ativo',
};

export const mockGetParticipacoesSaldoExtratoSuccessData: GetParticipacoesSaldoExtratoReturnType = {
	dataSaldoAnterior: '2023-12-01',
	dataSaldoAtual: '2024-02-01',
	saldoParticipante: {
		indices: [
			{
				indice: 'Índice 01',
				extratoPorContaPlano: [
					{
						contaPlano: 'Conta Plano 01',
						valores: { anterior: 2000, movimento: -2000, atual: 0, rentabilidade: 0 },
						cotas: { anterior: 10, movimento: -10, atual: 0, rentabilidade: 0 },
					},

					{
						contaPlano: 'Conta Plano 02',
						valores: { anterior: 0, movimento: 2000, atual: 2100, rentabilidade: 100 },
						cotas: { anterior: 0, movimento: 10, atual: 10, rentabilidade: 10 },
					},

					{
						contaPlano: 'Conta Plano 03',
						valores: { anterior: 500, movimento: 0, atual: 510, rentabilidade: 10 },
						cotas: { anterior: 5, movimento: 5, atual: 5, rentabilidade: 10 },
					},
				],
			},

			{
				indice: 'Índice 02',
				extratoPorContaPlano: [
					{
						contaPlano: 'Conta Plano 01',
						valores: { anterior: 2000, movimento: 1000, atual: 3050, rentabilidade: 50 },
						cotas: { anterior: 10, movimento: 5, atual: 15, rentabilidade: 10 },
					},
				],
			},
		],
		valorTotal: { anterior: 4500, movimento: 1000, atual: 5660, rentabilidade: 160 },
	},

	saldoPatrocinadora: {
		indices: [
			{
				indice: 'Índice 01',
				extratoPorContaPlano: [
					{
						contaPlano: 'Conta Plano 01',
						valores: { anterior: 500, movimento: 1000, atual: 1550, rentabilidade: 50 },
						cotas: { anterior: 2, movimento: 4, atual: 6, rentabilidade: 10 },
					},
				],
			},
		],
		valorTotal: { anterior: 500, movimento: 1000, atual: 1550, rentabilidade: 50 },
	},

	valorTotal: { anterior: 750, movimento: 1000, atual: 4000, rentabilidade: 210 },
};

export const mockGetParticipacoesPpeSuccessData: GetParticipacoesPpeReturnType = {
	ppe: false,
};

export const mockGetParticipacoesUsPersonSuccessData: GetParticipacoesUsPersonReturnType = {
	'Possuo outra nacionalidade ou outra cidadania além da brasileira.': false,
	'Possuo endereço, telefone ou caixa postal nos EUA.': false,
	'Possuo imóvel nos EUA.': false,
	'Possuo visto de residência permanente nos EUA (Green Card).': false,
	'Permaneci por mais de 30 dias nos EUA no último ano (exceto para fins de estudos).': false,
	'Possuo participação societária (mais de 10%) em alguma empresa nos EUA.': false,
	'Possuo conta em instituição financeira nos EUA.': false,
	'Possuo fonte de renda nos EUA.': false,
	'Possuo residência fiscal, bens, ou qualquer valor tributário em outro país.': false,
	'NÃO, nenhuma das alternativas anteriores.': false,
};

export const mockGetInformacoesTipoContaBancariaSuccessData: GetInformacoesTipoContaBancariaReturnType[] = [
	{ bancoId: 0, banco: 'Banco 01' },
	{ bancoId: 1, banco: 'Banco 02' },
	{ bancoId: 2, banco: 'Banco 03' },
	{ bancoId: 3, banco: 'Banco 04' },
	{ bancoId: 4, banco: 'Banco 05' },
];

export const mockGetInformacoesTipoSexoSuccessData: GetInformacoesTipoSexoReturnType[] = [
	{ tipoSexoId: 0, tipoSexo: 'Feminino' },
	{ tipoSexoId: 1, tipoSexo: 'Masculino' },
	{ tipoSexoId: 2, tipoSexo: 'Outro' },
	{ tipoSexoId: 3, tipoSexo: 'Não informado' },
];

export const mockGetInformacoesTipoEstadoCivilSuccessData: GetInformacoesTipoEstadoCivilReturnType[] = [
	{ tipoEstadoCivilId: 0, tipoEstadoCivil: 'Solteiro' },
	{ tipoEstadoCivilId: 1, tipoEstadoCivil: 'Casado' },
	{ tipoEstadoCivilId: 2, tipoEstadoCivil: 'União Estável' },
	{ tipoEstadoCivilId: 3, tipoEstadoCivil: 'Divorciado' },
	{ tipoEstadoCivilId: 4, tipoEstadoCivil: 'Viúvo' },
];

export const mockGetCalculoInstitutosLegais: PostCalculoInstitutosLegaisReturnType[] = [
	{ tipoBeneficio: 'Portabilidade', tipoBeneficioId: 1, valorSaldo: 25606.1 },
	{ tipoBeneficio: 'Restituição de Contribuição', tipoBeneficioId: 2, valorSaldo: 12313.42 },
	{ tipoBeneficio: 'Autopatrocinio', tipoBeneficioId: 3, valorSaldo: null },
	{ tipoBeneficio: 'Aposentadoria por Idade', tipoBeneficioId: 4, valorSaldo: 4242.22 },
	{ tipoBeneficio: 'BPD', tipoBeneficioId: 5, valorSaldo: null },
];

export const mockGetDadosSimulacaoBeneficio: GetDadosSimulacaoBeneficioReturnType = {
	tipoBeneficioId: 1,
	valorBeneficioMinimo: 600,
	dadosParticipante: {
		plano: 'Plano 1',
		planoId: 1,
		valorSalario: 4560.23,
		saldos: {
			saldoTotalParticipante: 893847.23,
			saldoTotalPatrocinadora: 673674.73,
			saldoTotal: 1567521.96,
			saldoResgatavelParticipante: 893847.23,
			saldoResgatavelPatrocinadora: 471572.31,
			saldoResgatavelTotal: 1365419.54,
		},
		percentualResgate: 1,
	},
	contribuicoes: [],
	tipoRendas: [
		{
			tipoRenda: 'Pagamento Único',
			tipoRendaId: 1,
			mesesParcelasMaximo: 1,
			mesesParcelasMinimo: 1,
			mesesParcelaVariacao: 0,
			percentualRendaSaldoMaximo: 0,
			percentualRendaSaldoMinimo: 0,
			percentualRendaSaldoVariacao: 0,
			percentualRendaReaisMinimo: 0,
			percentualRendaReaisMaximo: 0,
			percentualSaqueMaximo: 0,
			percentualSaqueMinimo: 0,
			percentualSaqueVariacao: 0,
			multiplicadorBeneficioMinimo: 1,
			possuiCalculoSn: true,
		},
		{
			tipoRenda: 'Prazo Certo',
			tipoRendaId: 4,
			mesesParcelasMaximo: 12,
			mesesParcelasMinimo: 1,
			mesesParcelaVariacao: 1,
			percentualRendaSaldoMaximo: 0,
			percentualRendaSaldoMinimo: 0,
			percentualRendaSaldoVariacao: 0,
			percentualRendaReaisMinimo: 0,
			percentualRendaReaisMaximo: 0,
			percentualSaqueMaximo: 30,
			percentualSaqueMinimo: 0,
			percentualSaqueVariacao: 1,
			multiplicadorBeneficioMinimo: 1,
			possuiCalculoSn: true,
		},
	],
};

export class ParticipanteApi extends BaseApi implements IParticipanteApi {
	public getContribuicoesById: GetByIdFn<GetContribuicoesReturnType> = async () => {
		return this.doRequest(mockGetContribuicoesSuccessData);
	};

	public getContribuicoesAlteracaoCusteioContasById: GetByIdFn<GetContribuicoesAlteracaoCusteioContasReturnType> =
		async () => {
			return await this.doRequest(mockGetContribuicoesAlteracaoCusteioPlanoBasicoSuccessData);
		};

	public postContribuicoesAlteracaoCusteioContasById: SendByIdFn<PostContribuicoesAlteracaoCusteioContasPayloadType> =
		async () => {
			return this.doRequest();
		};

	public getContribuicoesAlteracaoCusteioEstimativaAcumuloById: GetByIdFn<GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType> =
		async () => {
			return await this.doRequest(mockGetContribuicoesAlteracaoCusteioEstimativaAcumuloSuccessData);
		};

	public getContribuicoesSimulacoesEstimativaAcumuloById: GetByIdFn<GetContribuicoesSimulacoesEstimativaAcumuloReturnType> =
		async () => {
			return await this.doRequest(mockGetContribuicoesSimulacoesEstimativaAcumuloSuccessData);
		};

	public getContribuicoesAlteracaoCusteioBeneficioFiscalById: GetByIdFn<GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType> =
		async () => {
			return await this.doRequest(mockGetContribuicoesAlteracaoCusteioBeneficioFiscalSuccessData);
		};

	public getTotaisContribuicoesById: GetByIdFn<GetTotaisContribuicoesReturnType> = async () => {
		return this.doRequest(mockGetTotaisContribuicoesSuccessData);
	};

	public getHistoricoMensalContribuicoesById: GetByIdFn<GetHistoricoMensalContribuicoesReturnType> = async () => {
		return this.doRequest(mockGetHistoricoMensalContribuicoesSuccessData);
	};

	public getBeneficiosSaldosById: GetByIdFn<GetBeneficiosSaldosReturnType> = async () => {
		return this.doRequest(mockGetBeneficiosSaldosSuccessData);
	};

	public getParticipacoesById: GetByIdFn<GetParticipacoesReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesSuccessData);
	};

	public getParticipacoesBeneficiosById: GetByIdFn<GetParticipacoesBeneficiosReturnType[]> = async () => {
		return this.doRequest(mockGetParticipacoesBeneficiosSuccessData);
	};

	public getParticipacoesPlanosById: GetByNullableIdFn<GetParticipacoesPlanosReturnType[]> = async () => {
		return this.doRequest(mockGetParticipacoesPlanosSuccessData);
	};
	public getFichaFinanceiraAssistidoById: GetByIdFn<
		GetFichaFinanceiraAssistidoReturnType[],
		GetFichaFinanceiraAssistidoArgs
	> = async (id, args) => {
		const result: GetFichaFinanceiraAssistidoReturnType[] = [];

		if (args?.filtro === 'Custom') {
			for (let i = args.anoFim; i >= args.anoInicio; i--) result.push(getMockGetFichaFinanceiraDataByAno(i));
		} else {
			result.push(getMockGetFichaFinanceiraDataByAno(new Date().getFullYear()));
			if (args?.filtro === 'DoisAnos') result.push(getMockGetFichaFinanceiraDataByAno(new Date().getFullYear() - 1));
		}

		return this.doRequest(result);
	};

	// Calculo Benefício
	public postCalculoInstitutosLegais: GetByIdFn<PostCalculoInstitutosLegaisReturnType[]> = async () => {
		return this.doRequest(mockGetCalculoInstitutosLegais);
	};

	public getDadosSimulacaoBeneficio: GetByIdFn<GetDadosSimulacaoBeneficioReturnType> = async () => {
		return this.doRequest(mockGetDadosSimulacaoBeneficio);
	};

	public postRequerimentoBeneficio: SendByIdFn<PostRequerimentoBeneficioPayloadType> = async () => {
		return this.doRequest();
	};

	// TODO - Refatorar essa implementação
	public getExtratoContribuicaoById: GetByIdFn<GetExtratoContribuicaoReturnType[], GetExtratoContribuicaoArgs> = async (
		id,
		args,
	) => {
		const result: GetExtratoContribuicaoReturnType[] = [];
		const dataFim = args?.dataFim ?? new Date();
		const dataInicio = args?.dataInicio ?? new Date('2023-12-01');

		if (args?.filtro === 'Anual') {
			const dataAtual = new Date();
			const dadosAno = mockGetExtratoContribuicaoByIdAndFilterSuccessData.filter(
				(dado) => dado.ano === dataAtual.getFullYear(),
			);

			result.push(...dadosAno);
		} else {
			const dadosFiltrados: GetExtratoContribuicaoReturnType[] = mockGetExtratoContribuicaoByIdAndFilterSuccessData.map(
				(dado) => {
					const dadosFiltradosPorPeriodo: GetExtratoContribuicaoReturnType = {
						ano: dado.ano,
						mes: dado.mes,
						movimentos: [],
					};

					dado.movimentos.forEach((m) => {
						if (m.data >= dataInicio && m.data <= dataFim) {
							dadosFiltradosPorPeriodo.movimentos.push(m);
						}
					});

					return dadosFiltradosPorPeriodo;
				},
			);

			result.push(...dadosFiltrados.filter((d) => d.movimentos.length > 0));
		}

		return this.doRequest(result);
	};

	public getParticipacoesSaldoById: GetByIdFn<GetParticipacoesSaldoReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesSaldoSuccessData);
	};

	public getParticipacoesComposicaoById: GetByIdFn<GetParticipacoesComposicaoSaldoReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesComposicaoSaldoSuccessData);
	};

	// Beneficiários
	public getBeneficiariosById: GetByIdFn<GetBeneficiariosReturnType[]> = async () => {
		return this.doRequest(mockGetBeneficiariosSuccessData);
	};
	public putBeneficiariosById: SendByIdFn<readonly GetBeneficiariosReturnType[]> = async () => {
		return;
	};

	public getNotificacoes: GetFn<GetNotificacoesReturnType[]> = async () => {
		return this.doRequest(mockGetNotificacoesSuccessData);
	};

	public getMinhaRentabilidadeById: GetByIdFn<GetMinhaRentabilidadeReturnType> = async () => {
		return this.doRequest(mockGetMinhaRentabilidadeSuccessData);
	};

	public getEmprestimoById: GetByIdFn<GetEmprestimoReturnType> = async () => {
		return this.doRequest(mockGetEmprestimoSuccessData);
	};

	public getComposicaoSaldoById: GetByIdFn<GetComposicaoSaldoReturnType> = async () => {
		return this.doRequest(mockGetComposicaoSaldoSuccessData);
	};

	// Dados Cadsatrais

	// Dados pessoais
	public getParticipacoesDadosPessoaisById: GetByIdFn<GetParticipacoesDadosPessoaisReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesDadosPessoaisSuccessData);
	};
	public postParticipacoesDadosPessoaisById: SendByIdFn<PostParticipacoesDadosPessoaisPayloadType> = async () => {
		return this.doRequest();
	};

	// Conta bancária
	public getParticipacoesDadosContaBancariaById: GetByIdFn<readonly GetParticipacoesDadosContaBancariaReturnType[]> =
		async () => {
			return this.doRequest(mockGetParticipacoesDadosContaBancariaSuccessData);
		};
	public postParticipacoesDadosContaBancaria: SendByIdFn<readonly PostParticipacoesDadosContaBancariaPayloadType[]> =
		async () => {
			return this.doRequest();
		};

	// Contato
	public getParticipacoesDadosContatoById: GetByIdFn<GetParticipacoesDadosContatoReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesContatoSuccessData);
	};
	public postParticipacoesDadosContatoTelefone: SendByIdFn<
		readonly PostParticipacoesDadosContatoTelefonePayloadType[]
	> = async () => {
		return this.doRequest();
	};
	public postParticipacoesDadosContatoEndereco: SendByIdFn<
		readonly PostParticipacoesDadosContatoEnderecoPayloadType[]
	> = async () => {
		return;
	};
	public postParticipacoesDadosContatoEmail: SendByIdFn<readonly PostParticipacoesDadosContatoEmailPayloadType[]> =
		async () => {
			return this.doRequest();
		};

	public putParticipacoesContatoById: SendByIdFn<GetParticipacoesDadosContatoReturnType> = async () => {
		return;
	};

	// Planos
	public getParticipacoesDadosPlanosById: GetByIdFn<GetParticipacoesDadosPlanosReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesDadosPlanosSuccessData);
	};
	public putParticipacoesDadosPlanosById: SendByIdFn<GetParticipacoesDadosPlanosReturnType> = async () => {
		return this.doRequest();
	};

	// PPE
	public getParticipacoesPpeById: GetByIdFn<GetParticipacoesPpeReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesPpeSuccessData);
	};
	public putParticipacoesPpeById: SendByIdFn<GetParticipacoesPpeReturnType> = async () => {
		return this.doRequest();
	};

	// US Person
	public getParticipacoesUsPersonById: GetByIdFn<GetParticipacoesUsPersonReturnType> = async () => {
		return this.doRequest(mockGetParticipacoesUsPersonSuccessData);
	};
	public putParticipacoesUsPersonById: SendByIdFn<GetParticipacoesUsPersonReturnType> = async () => {
		return this.doRequest();
	};

	// TODO - Fazer uma implementação mockada aqui
	public getParticipacoesSaldoExtratoById: GetByIdFn<
		GetParticipacoesSaldoExtratoReturnType,
		GetParticipacoesSaldoExtratoArgs
	> = async () => {
		return this.doRequest(mockGetParticipacoesSaldoExtratoSuccessData);
	};

	public getBeneficiosContrachequeById: GetByIdFn<readonly GetBeneficiosContrachequeReturnType[]> = async () => {
		return this.doRequest(mockGetBeneficiosContrachequeSuccessData);
	};

	public getBeneficiosRendasById: GetByIdFn<readonly GetBeneFiciosRendasReturnType[]> = async () => {
		return this.doRequest(mockGetBeneficiosRendasSuccessData);
	};

	public getBeneficiosSaldoDetalhadoById: GetByIdFn<GetBeneficiosSaldoDetalhadoReturnType> = async () => {
		return this.doRequest(mockGetBeneficiosSaldoDetalhadoSuccessData);
	};

	// Informações
	public getInformacoesTipoContaBancaria: GetFn<readonly GetInformacoesTipoContaBancariaReturnType[]> = async () => {
		return this.doRequest(mockGetInformacoesTipoContaBancariaSuccessData);
	};

	public getInformacoesTipoSexo: GetFn<readonly GetInformacoesTipoSexoReturnType[]> = async () => {
		return this.doRequest(mockGetInformacoesTipoSexoSuccessData);
	};

	public getInformacoesTipoEstadoCivil: GetFn<readonly GetInformacoesTipoEstadoCivilReturnType[]> = async () => {
		return this.doRequest(mockGetInformacoesTipoEstadoCivilSuccessData);
	};
}
